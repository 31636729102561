import axios from 'axios'


const getAPI = axios.create({
    baseURL: 'https://in.1312.kz/api',
    // baseURL: 'http://127.0.0.1:8000/api',
    // baseURL: 'http://192.168.2.4:8000/api'
    // baseURL: 'http://192.168.2.5:8000/api'
})

const tgAPI = axios.create({
    baseURL: 'https://api.telegram.org/'
})

export { getAPI, tgAPI }