<template>
	<div>
		<v-app-bar app color="primary" dark>
			<div class="d-flex align-center">
				<v-btn text @click="$router.push(`/?agent_fio=${login_to_fio[user_fio]}`).then(() => {$router.go()})">
					<span class="mr-2">Входящие (смена)</span>
				</v-btn>
				<v-btn text @click="$router.push('/').then(() => {$router.go()})">
					<span class="mr-2">Входящие (всё)</span>
				</v-btn>
			</div>
			<v-spacer></v-spacer>
			<v-avatar color="indigo" class="mr-1">
				<v-icon dark> mdi-account-circle </v-icon>
			</v-avatar>
			
			<v-btn text @click="dialog = true">
				<span class="mr-2">{{ login_to_fio[user_fio] || "Войти" }}</span>
			</v-btn>
		</v-app-bar>

		<v-row justify="center">
			<v-dialog v-model="dialog" persistent max-width="600px">
				<v-card v-if="!is_auth">
					<v-card-title>
						<span class="text-h5">Войти</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-text-field label="Логин" v-model="login_field"></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Пароль"
										type="password"
										v-model="password_field"
									></v-text-field>
								</v-col>
							</v-row>
							<v-spacer></v-spacer>
							<p v-if="login_error">Не правильный логин или пароль</p>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialog = false"> Закрыть </v-btn>
						<v-btn color="blue darken-1" text :loading="btn_loading" @click="login()"> Войти </v-btn>
					</v-card-actions>
				</v-card>

				<v-card v-else>
					<v-card-title class="text-h5">Точно хотите выйти?</v-card-title>
					<v-card-text></v-card-text>
					<v-card-actions>
						<!-- <v-spacer></v-spacer> -->
						<v-btn color="green darken-1" text @click="dialog = false"> Отмена </v-btn>
						<v-btn color="green darken-1" text :loading="btn_loading" @click="logout()"> Выйти </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</div>
</template>

<script>
	import { getAPI } from "../axios-api"
	import tmc_users from '../tmc_users.json'
	
	export default {
		name: "Header",
		data() {
			return {
				login_to_fio: tmc_users,
				user_fio: this.$store.state.username != null ? this.$store.state.username : "Войти",
				dialog: false,
				login_field: null,
				password_field: null,
				is_auth: this.$store.state.is_auth,
				btn_loading: false,
				login_error: false,
				reload_png_link: '../../assets/reload.png',
			};
		},
		computed: {
			login_to_fio_computed() {
				return this.login_to_fio.length;
			},
		},
		methods: {
			login() {
				this.btn_loading = true;
				getAPI.post("/auth/", { login: this.login_field, password: this.password_field }).then((res) => {
					if (res.data.result == "ok") {
						this.$store
							.dispatch("tmcLogin", {
								login: this.login_field,
							})
							.then(() => {
								this.$router.go(0);
							})
							.catch(() => {
								this.btn_loading = false;
							});
					} else if (res.data.result == "error") {
						this.btn_loading = false;
						this.login_error = true;
						setTimeout(() => {
							this.login_error = false;
						}, 3000);
					}
				});
			},
			pull_fio(login) {
				return this.login_to_fio[login];
			},
			logout() {
				this.btn_loading = true;
				this.$store.dispatch("tmcLogout").then(() => {
					this.$router.go(0);
				});
			},
		},

		mounted() {
			console.log(this.$store.state);
		},
	};
</script>
