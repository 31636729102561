<template>
	<v-app>
		<Header />
		<v-main style="zoom:85%;">
			<transition name="fade" mode="out-in">
				<router-view />
			</transition>
		</v-main>
	</v-app>
</template>

<script>
	import Header from "./components/Header.vue";
	export default {
		name: "App",
		components: {
			Header,
		},
	};
</script>

<style>
	/* width */
	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	.menuable__content__active{
		zoom: 85%;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #1976d2;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #1370c0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
