import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import excel from "vue-excel-export"
import VueExcelXlsx from 'vue-excel-xlsx'
// import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
 

// Vue.use(DatetimePicker)

Vue.config.productionTip = false

Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$http = Axios
Vue.use(excel)
Vue.use(VueExcelXlsx)

// Vue.$store = store

new Vue({
  render: h => h(App),
  router,
  vuetify,
  store,
}).$mount('#app')
