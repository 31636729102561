import Vue from 'vue'
import Vuex from 'vuex'
import { getAPI } from './axios-api'
import tmc_users from './tmc_users.json'


Vue.use(Vuex)
export default new Vuex.Store({

  state: {
    accessToken: localStorage.getItem('token') || '',
    refreshToken: null,
    is_auth: localStorage.getItem('is_auth') || false,
    username: localStorage.getItem('username') || null,
    fullpath: null,
    user_fio: localStorage.getItem('user_fio'),
  },
  mutations: {
    updateStorage (state, { access, refresh }) {
      state.accessToken = access
      state.refreshToken = refresh
      localStorage.setItem('token', access)
      localStorage.setItem('is_auth', true)
    },
    destroyToken (state) {
      state.accessToken = null
      state.refreshToken = null
      localStorage.removeItem('token')
      localStorage.removeItem('is_auth')
    },

    saveLoginCredentials(state, {login}) {
      let login_to_fio = tmc_users
      state.user_fio = login_to_fio[login]
      state.username = login
      localStorage.setItem('username', login)
      localStorage.setItem('user_fio', login_to_fio[login])

      state.is_auth = true
      localStorage.setItem('is_auth', true)
    },

    removeLoginCredentials(state) {
      state.is_auth = false
      state.username = null
      state.user_fio = null
      localStorage.removeItem('username')
      localStorage.removeItem('is_auth')
    },

    saveFullPathForLogin(state, {path}) {
      state.fullpath = path
    }
  },
  getters: {
    loggedIn (state) {
      return state.accessToken != null
    },
  },
  actions: {
    userLogout (context) {
      if (context.getters.loggedIn) {
          context.commit('destroyToken')
      }
    },
    userLogin (context, usercredentials) {
      return new Promise((resolve, reject) => {
        getAPI.post('/api-token/', {
          username: usercredentials.username,
          password: usercredentials.password
        })
          .then(response => {
            context.commit('updateStorage', { access: response.data.access, refresh: response.data.refresh }) 
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    tmcLogin(context, usercredentials) {
      context.commit('saveLoginCredentials', { login: usercredentials.login }) 
    },

    tmcLogout(context) {
      context.commit('removeLoginCredentials')
    },

    saveFullPath(context, patharr) {
      context.commit('saveFullPathForLogin', { path: patharr.path})
    }
  }
})