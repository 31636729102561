import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

const Start = () => import("@/views/Start")
const IncomingCard = () => import("@/views/IncomingCard")
// const IncomingHistory = () => import("@/views/IncomingHistory")
const Login = () => import("@/views/Login")

Vue.use(Router)

// export default new Router({
//   mode: 'history', 
//   linkActiveClass: 'active',
//   scrollBehavior: () => ({ y: 0 }),
//   routes: configRoutes()
// })

// function configRoutes () {
//   return [
//     {
//       path: '/',
//       name: 'main',
//       component: Start,
//     },
//     {
//         path: '/card/',
//         name: 'card',
//         component: IncomingCard
//     }
//   ]
// }




const routes = [
  {
    path: '/',
    name: 'main',
    component: Start,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/',
    name: 'login',
    component: Login
  },
  {
    path: '/card/',
    name: 'card',
    component: IncomingCard,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/history/',
  //   name: 'history',
  //   component: IncomingHistory,
  //   meta: {
  //     requiresAuth: true
  //   },
  // },
  { path: "*", redirect: {path: '/'} }
]

const router = new Router({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // console.log('fullPath:', to.fullPath)
    // console.log('store fullPath:', store.state.fullpath)
    if (!store.state.is_auth) {
      store.dispatch('saveFullPath', { path: to.fullPath})
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;